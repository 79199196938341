import { type MetaFunction } from '@vercel/remix';
import { getProxySrc } from '../../libs/util/img-proxy';
import { getCommonMeta, mergeMeta } from './meta';

export const getRootMeta: MetaFunction = ({ data }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  const siteDetails = (data as any)?.siteDetails;

  if (!siteDetails) return [];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  const title: string = siteDetails.store.name || 'Ashley x Descend Store';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  const description = siteDetails?.site_settings?.description;
  const ogTitle = title;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ogDescription = description;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
  const ogImage = getProxySrc(siteDetails.store.logo?.url);
  const ogImageAlt = ogImage ? `${ogTitle} logo` : undefined;

  return [
    { title },
    { name: 'description', content: description },
    { property: 'og:title', content: ogTitle },
    { property: 'og:description', content: ogDescription },
    { property: 'og:image', content: ogImage },
    { property: 'og:image:alt', content: ogImageAlt },
  ];
};

export const getMergedRootMeta = mergeMeta(getCommonMeta, getRootMeta);
