import { useNavigate } from '@remix-run/react';
import { Link } from '@remix-run/react';
import { CloseIcon, SearchIcon } from '@storefront/ui-components/shared/icons';
import { buildProductUrl } from '@storefront/util/auction/buildProductUrl';
import { formatPrice } from '@storefront/util/prices';
import truncate from 'lodash/truncate';
import React, { useRef, useState } from 'react';
import { useSearchBox } from 'react-instantsearch';
import { useAuctionAutocomplete } from '../hooks/useAuctionAutocomplete';
import { useRegion } from '../hooks/useRegion';
import { IconButton } from '../shared/buttons';
import { Image } from '../shared/images/Image';

export const SearchBar = () => {
  const { auctions } = useAuctionAutocomplete();
  const navigate = useNavigate();
  const { region } = useRegion();
  const searchRef = useRef<HTMLInputElement>(null);
  const [hasFocus, setHasFocus] = useState(false);

  const { query, refine, clear } = useSearchBox();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    refine(e.target.value);
  };

  const handleClearSearch = () => {
    clear();
    setHasFocus(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchRef.current?.blur();
      if (query.trim()) {
        setHasFocus(false);
        navigate(`/search${window.location.search}`);
      }
    }
  };

  const shouldShowAutocomplete = query.length > 0 && hasFocus;

  return (
    <div className={`flex justify-center items-center ${shouldShowAutocomplete ? 'min-h-screen' : ''}`}>
      <div
        className={`${
          query.length > 0 || hasFocus ? 'w-[290px] md:w-[500px]' : 'w-32'
        } transition-all duration-100 ease-in-out`}
      >
        <div className="w-full rounded-lg relative">
          <div className="flex items-center relative">
            <div className="absolute">
              <SearchIcon className="w-6 h-6 ml-2 md:ml-3" />
            </div>
            <input
              ref={searchRef}
              type="text"
              placeholder="Search"
              value={query}
              onChange={handleInputChange}
              onFocus={() => setHasFocus(true)}
              onKeyDown={handleKeyDown}
              className="w-full outline-none border-none px-[30px] py-2 pl-[40px] h-9 md:h-auto text-lg bg-gray-100 rounded-full focus:border-none focus:ring-0"
            />
            {query.length > 0 && (
              <IconButton
                className="absolute right-2 cursor-pointer h-6 w-6"
                icon={() => <CloseIcon />}
                onClick={handleClearSearch}
              />
            )}
          </div>

          {shouldShowAutocomplete && (
            <div className="flex flex-col absolute z-10 w-full bg-white rounded-md max-h-[280px] overflow-y-auto p-0 mt-2 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
              {auctions.map((auction) => (
                <Link
                  key={auction.objectID}
                  className="p-2 hover:bg-gray-100 cursor-pointer flex flex-row align-middle items-center justify-between"
                  to={buildProductUrl(auction.handle)}
                  onClick={() => clear()}
                >
                  <div className="flex flex-row align-middle items-center gap-2">
                    <Image src={auction.imageLink} alt={auction.title} className="w-12 h-12 object-cover rounded-md" />
                    <div>
                      <div>{truncate(auction.title, { length: 30, separator: ' ' })}</div>
                      <div>
                        {formatPrice(auction.currentPrice, {
                          currency: region.currency_code,
                        })}
                        - {auction.collection}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
