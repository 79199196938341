import { useEnv } from '@storefront/ui-components/hooks/useEnv';
import { useStorefront } from '@storefront/ui-components/hooks/useStorefront';
import { PostalCodeModalContainer } from '@storefront/ui-components/postalCode/PostalCodeModalContainer';
import { IconButton } from '@storefront/ui-components/shared/buttons';
import { Drawer } from '@storefront/ui-components/shared/drawer';
import { useEffect } from 'react';
import { CloseIcon } from 'yet-another-react-lightbox';
import PostalCodeForm from '~/components/postal/PostalCodeForm';
import useUserLocation from '../../../libs/ui-components/hooks/useUserLocation';
import UnsupportedPostalCodeForm from './UnsupportedPostalCodeForm';

export const PostalCodeModal = () => {
  const { env } = useEnv();

  const { state, actions } = useStorefront();
  const isOpen = state.location.open;
  const unsupportedPostalCode = state.location.unsupportedPostalCode;
  const location = useUserLocation();

  const handleClose = () =>
    actions.toggleLocationModal({
      open: false,
      state: {
        unsupportedPostalCode: null,
      },
    });

  const handleUnsupportedPostalCode = (postalCode: string) => {
    actions.toggleLocationModal({
      open: true,
      state: {
        unsupportedPostalCode: postalCode,
      },
    });
  };

  // Note: currently on all pages, if a user does not have a location set, we will automatically open this modal
  // I hope we will revisit this in the future, because I'm not sold on the user experience flow of this - Jake
  useEffect(() => {
    if (env.SUPPRESS_POSTAL_MODAL === 'true') return;

    if (!isOpen && (location.suggested || !location.postalCode)) {
      actions.toggleLocationModal({
        open: true,
        state: {
          unsupportedPostalCode: null,
        },
      });
    }
  }, []);

  if (unsupportedPostalCode) {
    return (
      <>
        <PostalCodeModalContainer
          name="Postal Code"
          className="sm:!max-w-2xl"
          isOpen={isOpen}
          onClose={handleClose}
          preventClickOutside
          title="Don't Miss Out on Descending Deals"
        >
          <UnsupportedPostalCodeForm />
        </PostalCodeModalContainer>
      </>
    );
  }

  return (
    <>
      <PostalCodeModalContainer
        name="Postal Code"
        className="sm:!max-w-2xl"
        isOpen={isOpen}
        onClose={handleClose}
        preventClickOutside
        title="Update Location"
      >
        <PostalCodeForm onClose={handleClose} onUnsupportedPostalCode={handleUnsupportedPostalCode} />
      </PostalCodeModalContainer>
    </>
  );
};
