import { useCart } from '@storefront/ui-components/hooks/useCart';
import { Modal } from '@storefront/ui-components/shared/modals';
import { SignUpForm } from './SignupForm';
import { useSignUp } from '@storefront/ui-components/hooks/useSignup';

export const SignUpModal = () => {
  const { cart } = useCart();
  const { signUp, toggleSignUpModal } = useSignUp();

  return (
    <Modal name="Sign Up" isOpen={!!signUp.open} onClose={() => toggleSignUpModal(false)}>
      <div className="mb-4">
        <h2 className="text-lg font-bold text-gray-900">Sign Up</h2>
        Create an account and discover gems you won't want to miss!
      </div>
      <SignUpForm defaultValues={{ email: cart?.email ?? '' }} onSuccess={() => toggleSignUpModal(false)} />
    </Modal>
  );
};
