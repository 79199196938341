import { useSearchParams } from '@remix-run/react';
import { useCustomer } from '@storefront/ui-components/hooks/useCustomer';
import { useEnv } from '@storefront/ui-components/hooks/useEnv';
import _refiner from 'refiner-js';

const RefinerComponent = () => {
  const { env } = useEnv();

  const [searchParams] = useSearchParams();
  const term = searchParams.get('e2e');

  if (env.REFINER_API_KEY === undefined || term === 'test') return;

  const REFINER_API_KEY = env.REFINER_API_KEY;
  const { customer } = useCustomer();

  const customerId = customer?.id ?? '';
  const customerEmail = customer?.email ?? '';
  const customerFirstName = customer?.first_name ?? '';
  const customerLastName = customer?.last_name ?? '';
  const customerName = `${customerFirstName} ${customerLastName}`;

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window._refinerQueue = window._refinerQueue || []; function _refiner(){_refinerQueue.push(arguments);} _refiner('setProject', '${REFINER_API_KEY}'); (function(){var a=document.createElement("script");a.type="text/javascript";a.async=!0;a.src="https://js.refiner.io/v001/client.js";var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b)})();

          if (${customerId.length}) {
            _refiner('identifyUser', {
              id: '${customerId}',
              email: '${customerEmail}',
              name: '${customerName}',
            });
          }
        `,
        }}
      />
    </>
  );
};

export default RefinerComponent;
