import { useEnv } from '@storefront/ui-components/hooks/useEnv';

export const SetupClarity = () => {
  const { env } = useEnv();
  const projectId = env.CLARITY_PROJECT_ID;

  if (!projectId) return null;

  const clarityScript = `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${projectId}");
  `;

  return <script dangerouslySetInnerHTML={{ __html: clarityScript }} async />;
};
