import { useLocation } from '@remix-run/react';
import type { FC, ReactNode } from 'react';
import { CheckoutLayout } from './CheckoutLayout';
import { DefaultLayout } from './DefaultLayout';

export interface PageProps {
  className?: string;
  children: ReactNode;
}

const PageLayoutMap = {
  checkout: CheckoutLayout,
} as const;

export const Page: FC<PageProps> = (props) => {
  const location = useLocation();
  const paths = Object.keys(PageLayoutMap);
  const foundPath = paths.find((path) => location.pathname.includes(path)) as keyof typeof PageLayoutMap;
  const Layout = foundPath ? PageLayoutMap[foundPath] : DefaultLayout;
  return <Layout {...props} />;
};
