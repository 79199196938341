import type { Auction, ProductCategory, ProductCollection, ProductTag } from '@medusajs/medusa';
import { Await } from '@remix-run/react';
import { useRootLoaderData } from '@storefront/ui-components/hooks/useRootLoaderData';
import SearchDrawerDropdown from '@storefront/ui-components/search/SearchDrawerDropdown';
import { type FC, type PropsWithChildren, Suspense, useRef } from 'react';
import { isBrowser } from '../../util';
import { useKeyPress } from '../hooks/useKeypress';
import { useSearch } from '../hooks/useSearch';

const SearchDrawerInternal: FC<
  PropsWithChildren & {
    initial?: {
      auctions?: Auction[];
      collections?: ProductCollection[];
      categories?: ProductCategory[];
      tags?: ProductTag[];
    };
  }
> = ({ initial }) => {
  const { search, searchFetcher, toggleSearchDrawer, handleSearchChange, clearSearch, searchTerm } = useSearch();
  const searchInputRef = useRef<HTMLInputElement>(null);

  useKeyPress(['/', '?'], () => toggleSearchDrawer(true));

  const getSearchTerm = () => {
    if (!isBrowser()) return;
    const searchTerm = new URLSearchParams(window.location.search).get('term');
    return searchTerm || undefined;
  };

  const handleClearSearch = () => {
    if (searchInputRef?.current?.value) {
      searchInputRef.current.value = '';
      searchInputRef.current.focus();
    }
    clearSearch();
  };

  const handleSearchDrawerClose = () => {
    toggleSearchDrawer(false);
    setTimeout(() => {
      clearSearch();
    }, 200);
  };

  const fetchedProducts = searchFetcher.data?.auctions.map((a) => ({
    ...a.product,
    current_price: a.current_price,
  }));

  const initialProducts = initial?.auctions?.map((a) => ({
    ...a.product,
    current_price: a.current_price,
  }));

  const products = (fetchedProducts || initialProducts) ?? [];
  const collections = searchFetcher.data?.collections || initial?.collections || [];

  return (
    <SearchDrawerDropdown
      open={!!search.open}
      products={products}
      collections={collections}
      getSearchTerm={getSearchTerm}
      handleClearSearch={handleClearSearch}
      handleSearchChange={handleSearchChange}
      handleSearchDrawerClose={handleSearchDrawerClose}
      searchTerm={searchTerm}
      searchInputRef={searchInputRef}
    />
  );
};

export const SearchDrawer = () => {
  const rootData = useRootLoaderData();

  return (
    <Suspense>
      <Await resolve={rootData?.searchPromise}>
        {(search) => {
          return <SearchDrawerInternal initial={search} />;
        }}
      </Await>
    </Suspense>
  );
};
