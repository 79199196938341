import { useRootLoaderData } from '@storefront/ui-components/hooks/useRootLoaderData';

export const useSiteDetails = () => {
  const data = useRootLoaderData();
  const { store, site_settings, feature_flags, header_navigation_items, footer_navigation_items } =
    data?.siteDetails || {};

  return {
    store,
    site_settings,
    feature_flags,
    header_navigation_items,
    footer_navigation_items,
  };
};
