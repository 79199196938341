import type { PricedVariant } from '@medusajs/medusa/dist/types/pricing';
import { formatPrice, getVariantPrices } from '@storefront/util/prices';
import isNumber from 'lodash/isNumber';
import { type FC, useMemo } from 'react';

export interface ProductVariantWithCurrentPrice extends PricedVariant {
  current_price: number;
}

export interface ProductVariantPriceProps {
  variant: ProductVariantWithCurrentPrice;
  currencyCode: string;
}

export const ProductVariantPrice: FC<ProductVariantPriceProps> = ({ variant, currencyCode }) => {
  const prices = useMemo(() => getVariantPrices(variant, currencyCode), [variant, currencyCode]);
  const { original, calculated } = prices;
  const hasSale = isNumber(calculated) && calculated < original;

  return (
    <>
      {hasSale ? (
        <span className="inline-flex items-center gap-1">
          <span>{formatPrice(calculated, { currency: currencyCode })}</span>
          <s className="text-gray-400">{formatPrice(original, { currency: currencyCode })}</s>
        </span>
      ) : (
        formatPrice(original, { currency: currencyCode })
      )}
    </>
  );
};
