import { AuctionStatus } from 'descend-medusa/dist/types/auctions';

import { useAlgoliaAutocomplete } from './useAlgoliaAutocomplete';
import { useEnv } from './useEnv';

interface AuctionSearchHit {
  objectID: string;
  title: string;
  description: string;
  productCondition: string;
  imageLink: string;
  handle?: string;
  additionalImageLinks?: string[];
  originalPrice: number;
  currentPrice: number;
  priceDropTimeInMinutes: number;
  inventoryCount: number;
  collection?: string;
}

interface AuctionAutocomplete {
  auctions: AuctionSearchHit[];
}

export const useAuctionAutocomplete: () => AuctionAutocomplete = () => {
  const { env } = useEnv();
  const { indices } = useAlgoliaAutocomplete();
  const auctionIndex = indices.find((index) => index.indexId === env.ALGOLIA_INDEX_NAME);
  return { auctions: (auctionIndex?.hits || []) as unknown as AuctionSearchHit[] };
};
