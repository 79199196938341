import { Header as HeaderComponent } from '@storefront/ui-components/header/Header';
import { useSiteDetails } from '@storefront/ui-components/hooks/useSiteDetails';

export const SimpleHeader = () => {
  const { store } = useSiteDetails();

  if (!store) return <>Loading...</>;

  return (
    <header id="mkt-header" className="sticky top-0 z-40">
      <nav aria-label="Top">
        <div className="bg-white">
          <HeaderComponent />
        </div>
      </nav>
    </header>
  );
};
