import { type Customer } from '@medusajs/medusa';
import { Link } from '@remix-run/react';
import { type UserLocation } from '@storefront/ui-components/hooks/useUserLocation';
import { OutlineLocationOnIcon, SearchIcon } from '@storefront/ui-components/shared/icons';
import algoliasearch from 'algoliasearch';
import { type FC, type PropsWithChildren } from 'react';
import { InstantSearch } from 'react-instantsearch';
import { getRouting } from '~/components/search/routing';
import { Flags, useFlags } from '~/flags';
import { useEnv } from '../hooks/useEnv';
import { type TogglePayload, type ToggleableTargets } from '../providers/storefront-provider';
import { IconButton } from '../shared/buttons';
import { Image } from '../shared/images/Image';
import { HeaderUserMenu } from './HeaderUserMenu';
import { SearchBar } from './SearchBar';

export interface HeaderProps {
  customer?: Customer;
  location?: UserLocation;
  toggleSearch?: (isOpen: boolean) => void;
  toggleLocation?: (payload?: TogglePayload<ToggleableTargets.location>) => void;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ customer, location, toggleSearch, toggleLocation }) => {
  const isAlgoliaSearchVisible = useFlags(Flags.IS_ALGOLIA_SEARCH_VISIBLE);

  const { env } = useEnv();
  const searchClient = algoliasearch(env.ALGOLIA_APP_ID as string, env.ALGOLIA_SEARCH_API_KEY as string);
  const routing = getRouting(env.ALGOLIA_INDEX_NAME as string);

  return (
    <nav className="nav-bar flex h-12 sm:h-16 w-full items-center justify-between gap-2 sm:gap-4 border bg-white px-4 sm:px-8">
      <Link to="/">
        <>
          <Image alt="primary-logo" src={'/assets/logo.svg'} className="h-5 min-w-36 hidden sm:block" />
          <Image alt="primary-logo" src={'/assets/icon-descend.svg'} className="h-5 block sm:hidden" />
        </>
      </Link>

      <div className="flex flex-row align-middle items-center gap-2">
        {toggleSearch && !isAlgoliaSearchVisible ? (
          <IconButton
            className="!px-2 sm:!px-3 h-9 w-9 sm:h-10 sm:w-auto"
            icon={(props) => <SearchIcon {...props} />}
            prefix={<span className="hidden sm:inline">Search</span>}
            onClick={() => toggleSearch(true)}
          />
        ) : (
          <InstantSearch searchClient={searchClient} indexName={env.ALGOLIA_INDEX_NAME} routing={routing}>
            <SearchBar />
          </InstantSearch>
        )}
        {location && toggleLocation && (
          <IconButton
            className="header-postal-code hidden sm:inline-flex h-9 sm:h-10"
            icon={OutlineLocationOnIcon}
            prefix={location.postalCode}
            onClick={() => toggleLocation({ open: true })}
          />
        )}

        {(customer || (location && toggleLocation)) && (
          <HeaderUserMenu customer={customer} location={location} toggleLocation={toggleLocation} />
        )}
      </div>
    </nav>
  );
};
