import { Header as HeaderComponent } from '@storefront/ui-components/header/Header';
import { useCustomer } from '@storefront/ui-components/hooks/useCustomer';
import { useSearch } from '@storefront/ui-components/hooks/useSearch';
import { useSiteDetails } from '@storefront/ui-components/hooks/useSiteDetails';
import useUserLocation from '@storefront/ui-components/hooks/useUserLocation';

export const Header = () => {
  const location = useUserLocation();
  const { customer } = useCustomer();
  const { store } = useSiteDetails();
  const { toggleSearchDrawer, toggleLocation } = useSearch();

  if (!store) return <>Loading...</>;

  return (
    <header id="mkt-header" className="sticky top-0 z-40" key={location.city}>
      <nav aria-label="Top">
        <div className="bg-white">
          <HeaderComponent
            customer={customer}
            toggleSearch={toggleSearchDrawer}
            location={location}
            toggleLocation={toggleLocation}
          />
        </div>
      </nav>
    </header>
  );
};
