import type { Auction, ProductCategory, ProductCollection, ProductTag } from '@medusajs/medusa';
import { useFetcher } from '@remix-run/react';
import { useDebouncedCallback } from 'use-debounce';
import { loader as searchLoader } from '~/routes/search';
import { isBrowser } from '../../util';
import { useSendEvent } from '../../util/analytics/useAnalytics';
import { type PaginationConfig } from '../shared/pagination';
import { useStorefront } from './useStorefront';

export interface SearchPageData {
  auctions: Auction[];
  collections: ProductCollection[];
  categories: ProductCategory[];
  tags: ProductTag[];
  searchTerm: string | null;
  paginationConfig?: PaginationConfig;
}

export const useSearch = () => {
  const { state, actions } = useStorefront();
  const searchFetcher = useFetcher<typeof searchLoader>();
  const fetchSearch = () => searchFetcher.load(`/search?index${window?.location.search.replace('?', '&')}`);
  const sendSearchEvent = useSendEvent('search');

  const handleSearchChange = useDebouncedCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isBrowser()) return;
      const searchTerm = event.target.value;
      const isMoreThanOneTerm = searchTerm.length > 1;

      const url = new URL(window.location.href);

      url.searchParams.delete('page');

      if (!searchTerm) {
        url.searchParams.delete('term');
        window.history.replaceState({}, '', url.href);
        fetchSearch();
      } else if (isMoreThanOneTerm) {
        url.searchParams.set('term', searchTerm);
        window.history.replaceState({}, '', url.href);
        sendSearchEvent({ search_term: searchTerm });
        fetchSearch();
      } else {
        window.history.replaceState({}, '', url.href);
      }
    },
    200,
    { leading: true },
  );

  const clearSearch = () => {
    if (!isBrowser()) return;
    const url = new URL(window.location.href);
    url.searchParams.delete('term');
    window.history.replaceState({}, '', url.href);
    fetchSearch();
  };

  if (!state.search) throw new Error('useSearch must be used within the StorefrontContext.Provider');

  return {
    search: state.search,
    clearSearch,
    fetchSearch,
    searchTerm: searchFetcher.data?.searchTerm,
    searchFetcher,
    handleSearchChange,
    toggleSearchDrawer: actions.toggleSearchDrawer,
    toggleLocation: actions.toggleLocationModal,
  };
};
